import { Grid } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const IngredientList = ({ ingredients = [], text = '', totalIngredient, startIngredientIndex, endIngredientIndex }) => {
  const { t: translateIngredients } = useTranslation('ingredients');

  const listIngredients = ingredients.slice(startIngredientIndex, endIngredientIndex).map((val) => (
    <Grid className={styles.item} item xs={6} md={3} key={`ingredients-${uuidv4()}`}>
      <Link href={`ingredients/${val.slug}`} prefetch={false}>
        <div className={styles.ingredient}> {val?.name} </div>
      </Link>
    </Grid>
  ));

  return (
    <div className={styles.ingredientList}>
      <div className={styles.total}>
        <span>{translateIngredients('total_search_results')}</span>
        <strong className={styles.totalIngredient}>{totalIngredient}</strong>
        <strong> {text === '' ? translateIngredients('total') : text} </strong>
      </div>
      <Grid container className={styles.container}>
        {listIngredients}
      </Grid>
    </div>
  );
};

export default IngredientList;
