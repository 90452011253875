import { Chip, Grid } from '@material-ui/core';
import clsx from 'clsx';
import FavoriteButton from 'components-v2/organisms/pages/landingPageCam/mocules/FavoriteButton';
import ProductCardContent from 'components-v2/organisms/pages/landingPageCam/mocules/ProductCardContent';
import ProductCardInput from 'components-v2/organisms/pages/landingPageCam/mocules/ProductCardInput';
import TagComponent from 'components-v2/organisms/pages/landingPageCam/mocules/TagComponent';
import { LinkComp } from 'components/atoms';
import { MONITORING_COLLECTOR_TYPE } from 'constants/Enums';
import useTrackingMonitor from 'hooks/useTrackingMonitor';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { memo, useRef } from 'react';
import { DOMAIN_WEB_HOST } from 'sysconfig';
import { gtag } from 'utils';
import { ImageFallbackProductImage } from 'utils/ImageFallback';
import { getLinkProxy, getLinkProxyCDNFallback } from 'utils/ImageUtils';
import MonitorUtils from 'utils/MonitorUtils';
import useMobileV2 from 'zustand-lib/storeMobile';
import useSellers from 'zustand-lib/useSellers';
import styles from './styles.module.css';

// contain mobile v2 style
const ProductCard = ({ product, type, tag, index, isMobile, isHalfProgress = false, isCampaignPage = false, link, isLinkTagDeal, className }) => {
  const { t: translateCommon } = useTranslation('common');
  const searchInput = useRef([]);
  const router = useRouter();
  const trackingRef = useRef(null);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const getSellerByCode = useSellers((state) => state.getSellerByCode);

  const {
    isContractPrice = false,
    name,
    slug,
    discountPercent,
    defaultImage,
    tags,
    statusData = {},
    expiredDate = '',
    lotDates = [],
    isGift,
  } = product;
  useTrackingMonitor(trackingRef, product, isMobile);

  const isNearExpiration = lotDates?.find((item) => item?.isNearExpired === true) || {};

  const moveToDetail = () => {
    if (isMobileV2) router.push(`/product/${slug || ''}/loading`);
  };

  // udpate giá hợp đồng
  let nameLabel = '';
  if (isContractPrice) {
    nameLabel = translateCommon('contract_price');
  } else if (discountPercent) {
    nameLabel = `- ${discountPercent}%`;
  }

  return (
    <Grid
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={clsx(
        styles.product_card,
        {
          [styles.mobile_product_card]: isMobileV2,
        },
        className,
      )}
      onClick={moveToDetail}
      ref={trackingRef}
    >
      <Grid item container direction="column">
        {/* components discount tag, image, favorite btn */}
        <div style={{ position: 'relative', width: '100%' }}>
          {nameLabel && (
            <Chip
              label={nameLabel}
              className={clsx(!isMobileV2 ? styles.product_tag : styles.mobile_product_tag, {
                [styles.product_tab_contract]: isContractPrice,
              })}
            />
          )}

          <FavoriteButton product={product} isProductCard />

          <div className={clsx(!isMobileV2 ? styles.product_image : styles.mobile_product_image)}>
            <LinkComp href={`${DOMAIN_WEB_HOST}/product/${slug || ''}/loading`} prefetch={false} data-test={`product-no-${index}`}>
              <ImageFallbackProductImage
                src={getLinkProxy(defaultImage)}
                fallbackSrc={getLinkProxyCDNFallback(defaultImage)}
                alt={name && name}
                title={name && name}
                className={clsx(!isMobileV2 ? styles.image : styles.image_mobile)}
                layout="fill"
                onClick={async () => {
                  const sellerInfo = await getSellerByCode({ code: product?.sellerCode, tags });
                  gtag.viewItemInPage({ product: { ...product, sellerInfo }, path: router.pathname });
                  MonitorUtils.sendSKUEvent(MONITORING_COLLECTOR_TYPE.SKU_DETAIL_VIEW, { ...product, sellerInfo }, router.pathname);
                }}
              />
            </LinkComp>
          </div>

          <div
            style={{ display: 'flex', marginLeft: -1, marginRight: -1 }}
            className={clsx(!isMobileV2 ? styles.product_tags : styles.mobile_product_tags)}
          >
            <TagComponent
              product={{ tags, statusData, expiredDate: isNearExpiration?.expiredDate || expiredDate || '' }}
              isMobile={isMobile}
              isMobileV2={isMobileV2}
              isProductCard
              isGift={isGift}
              link={link}
              isLinkTagDeal={isLinkTagDeal}
            />
          </div>
        </div>
        {/* components tên, số lượng, hạn, tag */}
        <ProductCardContent
          row
          product={product}
          tag={tag}
          translateCommon={translateCommon}
          isMobileV2={isMobileV2}
          isCampaignPage={isCampaignPage}
        />
      </Grid>

      <Grid item>
        {/* components giá tiền, inputs, buttons */}
        <ProductCardInput
          searchInput={searchInput}
          index={index}
          product={product}
          type={type}
          row
          isHalfProgress={isHalfProgress}
          isCampaignPage={isCampaignPage}
          {...product}
          key={product?.sku}
          isMobile={isMobile}
          isDisplayPrice={false}
        />
      </Grid>
    </Grid>
  );
};

export default memo(ProductCard);
