import { Grid } from '@material-ui/core';
import Link from 'next/link';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const ManufacturerList = ({ manufacturers = [], text = '', translateCommon }) => (
  <div>
    <div className={styles.total}>
      <i>{translateCommon('display-amount-for-search-result').replace('{total}', manufacturers.length || 0)}</i>
      <strong> {text === '' ? translateCommon('all') : text} </strong>
    </div>
    <Grid container className={styles.container} spacing={2}>
      {manufacturers.map((val) => (
        <Grid item xs={3} key={uuidv4()}>
          <Link href={`manufacturers/${val.slug}`} prefetch={false}>
            <div className={styles.manufacturer}> {val?.name} </div>
          </Link>
        </Grid>
      ))}
    </Grid>
  </div>
);

export default ManufacturerList;
