import { Button, Divider, MenuItem, Typography } from '@material-ui/core';
import { MenuRounded as MenuRoundedIcon } from '@material-ui/icons';
import { MenuDropDown, MenuDropDownItem } from 'components/atoms';
import { BILL, FEEDBACK, LOGOUT_ICON_IMG, ORDER, POINTS, QR, REFER, REWARDS, STATISTICAL, USER, VIEWED, VOUCHER, WISHLIST } from 'constants/Icons';
import { USER_PROMO_CODES_URL } from 'constants/Paths';
import { useAuth, useCart } from 'context';
import { useModal } from 'hooks';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { ENV_COUNTRY } from 'sysconfig';
import { gtag } from 'utils';
import { formatCurrency, formatFloatNumber } from 'utils/FormatNumber';
import NewCustomModal from '../NewCustomModal';
import styles from './styles.module.css';

export default function Toggle({ point, balance }) {
  const { t: translateCommon } = useTranslation('common');
  const { t: translatePopupAction } = useTranslation('popup-action');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPoupLogout, toggleLogout] = useModal(false);
  const open = Boolean(anchorEl);
  const router = useRouter();
  const { clearCart } = useCart();
  const { logout } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleLogout = () => {
    logout();
    clearCart();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button className={styles.toggle_button} aria-haspopup="true" onClick={handleClick} cursor="pointer">
        <MenuRoundedIcon />
      </Button>
      <MenuDropDown anchorEl={anchorEl} open={open} handleClose={handleClose}>
        <MenuItem onClick={handleClose} className={styles.menu_item}>
          <div className={styles.top_menu}>
            <Typography className={styles.top_text} variant="h5">
              {translateCommon('debts')}
            </Typography>
            <Typography className={styles.top_text} variant="h5">
              {translateCommon('points')}
            </Typography>
          </div>
          <div className={styles.top_menu}>
            <Typography className={styles.top_text} variant="h5" color="primary">
              {formatCurrency(balance)}
            </Typography>
            <Typography className={styles.top_text} variant="h5" color="secondary">
              {formatFloatNumber(point)}
            </Typography>
          </div>
        </MenuItem>
        <Divider />

        <MenuDropDownItem handleClick={() => router.push('/my-account')} text={translateCommon('tab_menu.my_account')}>
          <USER />
        </MenuDropDownItem>
        {/* {level !== 'LEVEL_BLACKLIST' && FEATURE_LEVEL && (
          <MenuDropDownItem handleClick={() => router.push('/user/level')} text="Thông tin cấp bậc">
            <LEVEL />
          </MenuDropDownItem>
        )} */}
        <MenuDropDownItem handleClick={() => router.push('/users/loyalty_points')} text={translateCommon('tab_menu.my_points')}>
          <POINTS />
        </MenuDropDownItem>
        <MenuDropDownItem handleClick={() => router.push('/my-order')} text={translateCommon('tab_menu.my_orders')}>
          <ORDER />
        </MenuDropDownItem>

        <MenuDropDownItem handleClick={() => router.push('/user/dashboard')} text={translateCommon('tab_menu.statistics')}>
          <STATISTICAL />
        </MenuDropDownItem>

        <MenuDropDownItem handleClick={() => router.push('/users/referrals')} text={translateCommon('tab_menu.refer_friends')}>
          <REFER />
        </MenuDropDownItem>

        <MenuDropDownItem handleClick={() => router.push(USER_PROMO_CODES_URL)} text={translateCommon('tab_menu.my_discount')}>
          <VOUCHER />
        </MenuDropDownItem>

        <MenuDropDownItem handleClick={() => router.push('/users/my-ticket')} text={translateCommon('tab_menu.my_feedback')}>
          <FEEDBACK />
        </MenuDropDownItem>
        {ENV_COUNTRY === 'vn' && (
          <MenuDropDownItem
            handleClick={() => {
              gtag.clickTrackingOrder();
              router.push('https://tracuu.thuocsi.vn');
            }}
            text={translateCommon('tab_menu.bill_of_lading_lookup')}
          >
            <BILL />
          </MenuDropDownItem>
        )}
        <MenuDropDownItem handleClick={() => router.push('/qr')} text={translateCommon('tab_menu.qr_code_lookup')}>
          <QR />
        </MenuDropDownItem>
        <MenuDropDownItem handleClick={() => router.push('/user/wishlist')} text={translateCommon('tab_menu.interested_products')}>
          <WISHLIST />
        </MenuDropDownItem>

        <MenuDropDownItem text={translateCommon('tab_menu.viewed_products')} handleClick={() => router.push('/productviewed')}>
          <VIEWED />
        </MenuDropDownItem>
        <MenuDropDownItem text={translateCommon('tab_menu.reward_events')} handleClick={() => router.push('/users/rewards')}>
          <REWARDS />
        </MenuDropDownItem>

        <MenuDropDownItem handleClick={toggleLogout} text={translateCommon('logout')}>
          <LOGOUT_ICON_IMG />
        </MenuDropDownItem>
      </MenuDropDown>
      <NewCustomModal
        visible={showPoupLogout}
        onClose={toggleLogout}
        title={translateCommon('comfirmation')}
        content={translatePopupAction('popup-action:popup-logout.logout_right')}
        btnOk={translateCommon('yes')}
        onClickOk={handleLogout}
        btnOnClose={translateCommon('no')}
      />
    </>
  );
}
