import clsx from 'clsx';
import { Fab } from 'components/atoms';
import styles from './styles.module.css';

const getAlphabets = () => {
  const arr = [];
  for (let i = 'A'.charCodeAt(0); i <= 'Z'.charCodeAt(0); i += 1) arr.push(String.fromCharCode(i));
  arr.push('#');
  return arr;
};

const AlphabetFilter = ({ word, handleChangeWord, isMobile }) => {
  const characters = getAlphabets();
  return (
    <div className={clsx(styles.alphabetFilterWrapper, isMobile && styles.mobileAlphabetFilterWrapper)}>
      {characters.map((character) => (
        <Fab
          className={clsx(styles.alphabetFilterItem, { [styles.alphabetFilterItemActive]: character === word })}
          handleClick={handleChangeWord}
          key={`fab-alphabet-${character}`}
        >
          {character}
        </Fab>
      ))}
    </div>
  );
};

export default AlphabetFilter;
