import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ProductCardVertical } from 'components-v2/mocules/mobile/CardProduct';
import ProductPreview from 'components-v2/mocules/mobile/SliderMobile/ProductPreview';
import { LinkComp } from 'components/atoms';
import { MAP_BTN_TITLE_BY_REDIRECT_URL, settingsBestProduct, settingsProduct } from 'constants/data';
import { DOUBLE_ARROW_ICON, SAME_INGREDIENT, SAME_STORE } from 'constants/Images';
import useOnScreen from 'hooks/useOnScreen';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { gtag } from 'utils';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import useMobileV2 from 'zustand-lib/storeMobile';
import { ChevronRight } from '@material-ui/icons';
import styles from './styles.module.css';

const SliderProduct = ({
  children,
  name,
  redirect,
  viewMore,
  page = "PRD_DETAIL",
  icon,
  iconWidth,
  iconHeight,
  isBestProducts = false,
  loading = false,
  type = '',
  products, // props using for mobile v2
  className,
  hideArrow = false,
}) => {
  const { t: translateCommon } = useTranslation('common');
  const ref = useRef(null);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const TITLE_ICON = name === 'Sản phẩm cùng nhà bán hàng' ? SAME_STORE : SAME_INGREDIENT;
  const SlideItems = products?.map((item) => <ProductCardVertical {...item} />);

  const wrapperRef = useRef(null);

  const visible = useOnScreen(wrapperRef);

  useEffect(() => {
    if (ref?.current)
      if (visible) {
        ref.current.slickPlay();
      } else {
        ref.current.slickPause();
      }
  }, [visible]);

  return (
    <Paper
      ref={wrapperRef}
      className={clsx(
        styles.wrapper,
        isBestProducts && styles.bestProductsWrapper,
        {
          [styles.wrapper_mv2]: isMobileV2,
        },
        className,
      )}
    >
      <Box
        className={clsx(styles.icon_name, isBestProducts && styles.bestProducts, {
          [styles.icon_name_mv2]: isMobileV2,
        })}
      >
        {isMobileV2 && icon && <ImageFallbackStatic src={icon} width={iconWidth || 24} height={iconHeight || 24} alt="icon" />}

        {!isMobileV2 && icon && <ImageFallbackStatic src={icon} width={iconWidth || 40} height={iconHeight || 40} alt="icon" />}
        <Typography
          className={clsx(styles.name, isBestProducts && styles.bestProductsName, {
            [styles.name_mv2]: isMobileV2,
          })}
          variant="h5"
        >
          {name}
        </Typography>
        {isBestProducts && (
          <Box className={styles.bestProducts_view_more}>
            <a href={`${redirect}`}>
              <span>{translateCommon('see_more')}</span>
              <ImageFallbackStatic src={DOUBLE_ARROW_ICON} width={12} height={12} alt="icon" />
            </a>
          </Box>
        )}
      </Box>
      {isBestProducts &&
        (isMobileV2 ? (
          <ProductPreview slideItems={SlideItems} appendSettings={{ initialSlide: SlideItems?.length < 3 ? 0 : 1 }} />
        ) : (
          <Slider
            ref={ref}
            {...settingsBestProduct}
            slidesToShow={children.length < 3 ? children.length : 3}
            initialSlide={children.length < 3 ? 0 : 1}
            centerMode={children.length >= 3}
            autoplay={visible}
            arrows={!hideArrow}
          >
            {children}
          </Slider>
        ))}

      {!isBestProducts &&
        (isMobileV2 ? (
          <ProductPreview slideItems={SlideItems} appendSettings={{ infinite: SlideItems?.length > 4 }} />
        ) : (
          <Slider ref={ref} {...settingsProduct} infinite={children.length > 4} autoplay={visible} arrows={!hideArrow}>
            {children}
          </Slider>
        ))}

      {viewMore && !isBestProducts && (
        <Box
          className={styles.see_more_wrapper}
          onClick={() => {
            if (type === 'SAME_MANUFACTURER') {
              gtag.clickViewAllProductSameManufacturer();
            }

            if (type === 'SAME_INGREDIENT') {
              gtag.clickViewAllProductSameIngredient();
            }
          }}
        >
          <LinkComp href={`${redirect}`} className={styles.see_more_btn}>
            <span>{translateCommon('see_more')}</span>
            <ChevronRight className={styles.see_more_icon} />
          </LinkComp>
        </Box>
      )}
    </Paper>
  );
};

export default SliderProduct;
