/* eslint-disable react/no-danger */
import { Divider, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { LinkComp } from 'components/atoms';
import { INGREDIENT } from 'constants/Paths';
import { useSetting } from 'context';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

export default function ProductDetailTabsV2({ data, product, ingredients = [], isMobile }) {
  const { t: translateProductDetail } = useTranslation('productDetail');
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight || 0);
  });

  const { description = {} } = product || {};
  const { mapIngredients = null } = useSetting();
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const tabPanels = [
    {
      label: translateProductDetail('gerenal_information'),
      value: 0,
      data: data.slice(0, 2),
    },
    {
      label: translateProductDetail('in_depth_information'),
      value: 1,
      data: data.slice(2),
    },
  ];

  return (
    <div className={styles.root}>
      <TabContext value={activeTab}>
        <div>
          <TabList
            TabIndicatorProps={{ className: styles.indicator }}
            onChange={handleChangeTab}
            aria-label="product details tabs"
            variant="scrollable"
            className={styles.tabs}
          >
            {tabPanels.map((tab) => (
              <Tab
                key={uuidv4()}
                classes={{
                  root: styles.tab,
                  selected: styles.active_tab,
                }}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </TabList>
        </div>

        {tabPanels.map((tabPanel, index) => (
          <TabPanel
            className={clsx(styles.tab_panel, {
              [styles.tab_panel_mv2]: isMobile,
            })}
            value={tabPanel?.value}
            key={tabPanel?.value}
          >
            <div className={clsx(!isMobile ? styles.item_wrapper : styles.mobile_item_wrapper)}>
              {tabPanel.data.map((tab) => (
                <div key={uuidv4()} className={clsx(!isMobile ? styles.item : styles.mobile_item, index === 0 && styles.general_item)}>
                  <div className={styles.item_label}>
                    <p>{tab.label}</p>
                    <div className={styles.item_label_border} />
                  </div>
                  <div className={styles.item_content}>
                    {/* ingredients */}
                    {tab?.code === 'ingredients' && ingredients?.length > 0 && (
                      <div className={styles.ingredientList}>
                        <div className={clsx(styles.ingredient_row, styles.ingredient_row_heading)}>
                          <span className={clsx(styles.ingredient_row_label, styles.ingredient_heading)}>
                            {translateProductDetail('composition')}
                          </span>
                          <span className={clsx(styles.ingredient_row_content, styles.ingredient_heading)}>
                            {translateProductDetail('ingredient_content')}
                          </span>
                        </div>
                        <Divider />
                        <div>
                          {ingredients?.map(({ ingredientCode, volume: ingredientVolume }) => {
                            const { slug, name: ingredientName = '' } = mapIngredients?.get(ingredientCode) || {};
                            return (
                              <div className={styles.ingredient_row}>
                                <LinkComp href={`${INGREDIENT}/${slug}`} className={styles.ingredient_row_label}>
                                  {ingredientName}
                                </LinkComp>
                                <span className={styles.ingredient_row_content}>{ingredientVolume}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {tab?.code !== 'ingredients' && <p dangerouslySetInnerHTML={{ __html: description[tab?.code] }} />}
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
}
