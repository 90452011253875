import { Fab, IconButton, Tooltip } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import clsx from 'clsx';
import { WISHLIST_ICON_1, WISHLIST_ICON_4 } from 'constants/Images';
import { useWishList } from 'context';
import { useTranslation } from 'next-i18next';
import { memo, useEffect, useState } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import useMobileV2 from 'zustand-lib/storeMobile';
import styles from './styles.module.css';

export const WISHLIST_IMAGE_INACTIVE = memo(() => <ImageFallbackStatic src={WISHLIST_ICON_1} width={18} height={18} />);
export const WISHLIST_IMAGE_ACTIVE = memo(() => <ImageFallbackStatic src={WISHLIST_ICON_4} width={18} height={18} />);

const FavoriteButton = ({ product, isProductCard = false }) => {
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const [isBookmarked, setIsFavorite] = useState(false);
  const { getWishlistBySkus, handleDeleteProductWishlist, handleUpdateWishlist, loading } = useWishList();
  const { t: translateCommon } = useTranslation('common');
  const loadData = async () => {
    const res = await getWishlistBySkus(product);
    setIsFavorite(res);
  };

  useEffect(() => {
    async function fetchData() {
      if (!loading) {
        loadData();
      }
    }
    fetchData();
  }, [loading]);

  const handleUpdateFavorite = async (item) => {
    const res = await handleUpdateWishlist(item);
    setIsFavorite(res);
  };

  const handleDeleteFavorite = async (item) => {
    const res = await handleDeleteProductWishlist(item);
    setIsFavorite(res);
  };

  if (isProductCard) {
    return (
      <Tooltip title={!isBookmarked ? translateCommon('btn.mark_wishlist_product') : translateCommon('btn.unmark_wishlist_product')}>
        {isBookmarked ? (
          <IconButton
            className={clsx(styles.favorite_icon_wrapper, { [styles.mobile_favorite_icon_wrapper]: isMobileV2 })}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDeleteFavorite(product);
            }}
          >
            <WISHLIST_IMAGE_ACTIVE className={styles.favorite_icon} />
          </IconButton>
        ) : (
          <IconButton
            className={clsx(styles.favorite_icon_wrapper, { [styles.mobile_favorite_icon_wrapper]: isMobileV2 })}
            onClick={(e) => {
              e.stopPropagation();
              handleUpdateFavorite(product);
            }}
          >
            <WISHLIST_IMAGE_INACTIVE />
          </IconButton>
        )}
      </Tooltip>
    );
  }

  return (
    <Tooltip title={!isBookmarked ? translateCommon('btn.mark_wishlist_product') : translateCommon('btn.unmark_wishlist_product')}>
      <Fab
        className={styles.icon}
        size="small"
        aria-label="like"
        onClick={(e) => {
          e.stopPropagation();
          if (isBookmarked) {
            handleDeleteFavorite(product);
          } else {
            handleUpdateFavorite(product);
          }
        }}
      >
        {isBookmarked ? (
          <FavoriteIcon style={{ fontSize: 24 }} className={styles.like} />
        ) : (
          <FavoriteBorderIcon style={{ fontSize: 24, color: '#606060' }} />
        )}
      </Fab>
    </Tooltip>
  );
};

export default FavoriteButton;
