/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
import { Box, CardContent, Grid, Tooltip, Typography } from '@material-ui/core';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import clsx from 'clsx';
import { LinkComp } from 'components/atoms';
import PointOfProduct from 'components/mocules/PointOfProduct';
import { FLAGSHIP_LABEL, MONITORING_COLLECTOR_TYPE } from 'constants/Enums';
import { ERROR_CODE_CART } from 'constants/ErrorCart';
import { PRODUCTS_LOADING_URL } from 'constants/Paths';
import { useSetting } from 'context';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ENV_COUNTRY, TAG_NEW } from 'sysconfig';
import { gtag } from 'utils';
import { formatDate3 } from 'utils/FormatDate';
import MonitorUtils from 'utils/MonitorUtils';
import useGetTagPromotion from 'zustand-lib/useGetTagPromotion';
import useSellers from 'zustand-lib/useSellers';
import styles from './styles.module.css';

const ProductCardContent = ({
  row = false,
  product = {},
  isSellerList = false,
  className = '',
  errorCode = null, // error code only show on cart item
  wishlist = false, // is page wishlist
  isCampaignPage = false, // để tránh hiển thị ??? price ở các trang khác campaign
  translateCommon = () => {},
  isMobileV2 = false,
}) => {
  const router = useRouter();
  const { getNameSeller } = useSetting();
  const { dataByPromoListsDetail, filterTagGift } = useGetTagPromotion();
  const getSellerByCode = useSellers((state) => state.getSellerByCode);

  const {
    name = '',
    isNew = false,
    volume = '',
    point = null,
    pointMultiplier = null,
    tags = [],
    slug = '',
    seller = {},
    isDeal = null,
    deal = null,
    sellerCode = '',
    isGift = false,
    sku = '',
    skuId: productCode = '',
    salePrice = 0,
    displayPrice = 0,
    productType = '',
    isHappeningCampaign = false, // is flash sale happening or not
    salePriceFormated = '',
    displayPriceFormated = '',
    percentDealSold = 0,
    isCampaign = false,
    campaign = {},
    discountPercent = 0,
    isContractPrice = false,
    currentPrice = 0,
    expiredDate = '',
    status,
  } = product;
  const needHidePrice = status === 'OUT_OF_STOCK' || status === 'SUSPENDED';
  const { isVip = null, isStore = false } = getNameSeller({ seller }) || {};

  // productCardContent use productCode and productCardContentNew use skuId
  const isTagGift = filterTagGift(sku, dataByPromoListsDetail, productCode);

  const SellerVip = () => (
    <span className={clsx(isTagGift ? styles.prioritizedGiftTag : '')}>
      <span style={{ color: '#B98F0F', fontSize: '14px', fontFamily: 'googleSansMedium' }}>{translateCommon(FLAGSHIP_LABEL)}</span>
      <span style={{ fontSize: '14px', color: '#000' }}> - </span>
    </span>
  );

  const isTagGiftByPromotion = () =>
    isTagGift ? (
      <span className={styles.giftPromo}>
        <span style={{ fontFamily: 'ggsm' }}>{translateCommon('gift_tag')}</span>
        <span style={{ color: 'black' }}> - </span>
      </span>
    ) : (
      ''
    );

  let hidenPrice = campaign?.salePriceLabel;
  if (isCampaign && campaign?.isValid === false) {
    const lengthSalePriceLabel = campaign?.salePriceLabel?.length;
    if (lengthSalePriceLabel > 3)
      hidenPrice = `${campaign?.salePriceLabel?.slice(0, lengthSalePriceLabel - 3)}.${campaign?.salePriceLabel?.slice(lengthSalePriceLabel - 3)}`;
  }

  const ContractPrice = () => <Box className={styles.contractLabel}>{translateCommon('contract_price')}</Box>;

  function GetPrice() {
    // wishlist page v2
    if (wishlist) {
      // khuyến mãi
      if ((isDeal && deal) || (isCampaign && percentDealSold < 100)) {
        return (
          <>
            <Grid container spacing={1} alignItems="center" className={clsx(styles.display_wishlist_price_wrapper)}>
              <div>
                {isContractPrice && <ContractPrice />}
                <span className={clsx(isMobileV2 ? styles.discount_percent_v2 : styles.discount_percent, { [styles.hidden]: discountPercent === 0 })}>
                  {`-${discountPercent}%`}
                </span>
                <span
                  className={clsx(styles.sale_price, isMobileV2 && styles.fs_12, { [styles.hidden]: salePriceFormated === displayPriceFormated })}
                >
                  {salePriceFormated}
                </span>
              </div>
              <Grid item style={{ padding: '0', width: 'auto' }}>
                <Typography className={clsx(styles.display_price, { [styles.fs_12]: isMobileV2 })}>{displayPriceFormated}</Typography>
              </Grid>
            </Grid>
          </>
        );
      }

      return (
        <div className={row ? styles.price_wrapper : clsx(styles.price_wrapper, styles.price_wrapper_column)}>
          <Grid container alignItems="stretch" className={clsx(styles.wishlist_price_wrapper)}>
            <Grid item style={{ paddingLeft: '0', width: 'auto' }}>
              {isContractPrice && <ContractPrice />}
              <Typography
                className={clsx(styles.display_price, {
                  [styles.display_price_mv2]: isMobileV2,
                })}
              >
                {displayPriceFormated}
              </Typography>
            </Grid>
            <Grid item>
              {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
              {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
            </Grid>
          </Grid>
          {isDeal && deal && (
            <Grid item style={{ width: 'auto' }}>
              <Typography
                className={clsx(styles.sale_price, {
                  [styles.sale_price_mv2]: isMobileV2,
                })}
              >
                {salePriceFormated}
              </Typography>
            </Grid>
          )}
        </div>
      );
    }

    if (isSellerList) {
      return (
        <>
          <div className={styles.salePrice_wrapper}>
            {isHappeningCampaign && salePrice && percentDealSold < 100 && (
              <Typography
                className={clsx(styles.sale_price, {
                  [styles.sale_price_mv2]: isMobileV2,
                })}
              >
                {salePriceFormated}
              </Typography>
            )}
            {!isHappeningCampaign && displayPrice && (
              <Typography
                className={clsx(styles.sale_price, {
                  [styles.sale_price_mv2]: isMobileV2,
                })}
              >
                {displayPriceFormated}
              </Typography>
            )}
          </div>
          <Grid
            container
            spacing={2}
            alignItems="center"
            className={clsx(styles.displayPrice_wrapper, {
              [styles.displayPrice_wrapper_mv2]: isMobileV2,
            })}
          >
            <Grid item style={{ padding: '0' }}>
              <Typography
                className={clsx(styles.display_price, {
                  [styles.display_price_mv2]: isMobileV2,
                })}
              >
                {((((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') && percentDealSold < 100) ||
                  (!isHappeningCampaign && !isCampaignPage)) &&
                  displayPriceFormated}
                {((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') &&
                  percentDealSold === 100 &&
                  salePriceFormated}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    if (isCampaign && percentDealSold < 100) {
      return (
        <>
          <Grid container spacing={2} alignItems="center" className={clsx(styles.displayPrice_wrapper)}>
            <Grid item style={{ padding: '0', width: 'auto' }}>
              <Typography
                className={clsx(styles.display_price, {
                  [styles.display_price_mv2]: isMobileV2,
                })}
              >
                {((((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') && percentDealSold < 100) ||
                  (!isHappeningCampaign && !isCampaignPage)) &&
                  displayPriceFormated}
                {((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') &&
                  percentDealSold === 100 &&
                  salePriceFormated}
                {!isHappeningCampaign && isCampaignPage && (ENV_COUNTRY === 'vn' ? `${hidenPrice}đ` : `$ ${hidenPrice}`)}
              </Typography>
            </Grid>
            {!isHappeningCampaign && isCampaignPage && displayPrice && (
              <Grid item style={{ padding: '0', width: 'auto' }}>
                <Typography
                  className={clsx(styles.sale_price, {
                    [styles.sale_price_mv2]: isMobileV2,
                  })}
                >
                  {displayPriceFormated}
                </Typography>
              </Grid>
            )}
            {isHappeningCampaign && salePrice && percentDealSold < 100 && (
              <Grid item style={{ padding: '0', width: 'auto' }}>
                <Typography
                  className={clsx(styles.sale_price, {
                    [styles.sale_price_mv2]: isMobileV2,
                  })}
                >
                  {salePriceFormated}
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      );
      // eslint-disable-next-line no-else-return
    } else if (isDeal && deal) {
      return (
        <>
          <Grid container spacing={1} alignItems="center" className={clsx(styles.displayPrice_wrapper)}>
            <Grid item style={{ padding: '0', width: 'auto' }}>
              <Typography
                className={clsx(styles.display_price, {
                  [styles.display_price_mv2]: isMobileV2,
                })}
              >
                {displayPriceFormated}
              </Typography>
            </Grid>
            <Grid item style={{ padding: '0', width: 'auto' }}>
              <Typography
                className={clsx(styles.sale_price, {
                  [styles.sale_price_mv2]: isMobileV2,
                })}
              >
                {salePriceFormated}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <div className={row ? styles.price_wrapper : clsx(styles.price_wrapper, styles.price_wrapper_column)}>
        <Grid container alignItems="stretch" className={clsx(styles.displayPrice_wrapper)}>
          <Grid item style={{ paddingLeft: '0', width: 'auto' }}>
            <Typography
              className={clsx(styles.display_price, {
                [styles.display_price_mv2]: isMobileV2,
              })}
            >
              {displayPriceFormated}
            </Typography>
          </Grid>
          <Grid item>
            {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
            {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
          </Grid>
        </Grid>
        {isDeal && deal && (
          <Grid item style={{ width: 'auto' }}>
            <Typography
              className={clsx(styles.sale_price, {
                [styles.sale_price_mv2]: isMobileV2,
              })}
            >
              {salePriceFormated}
            </Typography>
          </Grid>
        )}
      </div>
    );
  }

  return (
    <CardContent
      className={clsx(className, !isMobileV2 ? styles.product_content : styles.mobile_product_content, {
        [styles.product_content_column]: !row,
      })}
    >
      {/* 2 Oct 2023
      hidden plus point for new product card v5.1 */}
      {/* {!isSellerList && point > 0 && (
        <Grid
          container
          direction="row"
          alignItems="center"
          className={clsx(styles.wrapPlusPoint, {
            [styles.wrapPlusPoint_mv2]: isMobileV2,
          })}
        >
          <PointOfProduct point={point} pointMultiplier={pointMultiplier} />
        </Grid>
      )} */}
      <div className={styles.product_title_wrapper}>
        <div className={styles.product_title}>
          {isGift ? (
            <>
              {((isVip && isStore) || tags?.indexOf('DUREX') >= 0 || tags?.indexOf('SANOFI') >= 0) && <SellerVip />}
              <Typography
                className={clsx(styles.product_name, styles.giftlink, {
                  [styles.product_name_mv2]: isMobileV2,
                })}
                gutterBottom
                variant="h5"
                component="h2"
              >
                {isDeal && deal ? deal?.name : name}
              </Typography>
            </>
          ) : (
            <Typography
              style={{ lineHeight: '1.2' }}
              onClick={async () => {
                const sellerInfoData = await getSellerByCode({ code: sellerCode, tags });
                gtag.viewItemInPage({
                  product: { ...product, sellerInfoData },
                  path: router.pathname,
                });
                MonitorUtils.sendSKUEvent(MONITORING_COLLECTOR_TYPE.SKU_DETAIL_VIEW, { ...product, sellerInfoData }, router.pathname);
              }}
            >
              {isNew && !isVip && !isTagGift && (
                <Link href={`${PRODUCTS_LOADING_URL}?tag=${TAG_NEW}`} prefetch={false}>
                  <a>
                    <span className={styles.new_tag}>{translateCommon('text_new')} </span> <span className={styles.dash_tag}> - </span>
                  </a>
                </Link>
              )}

              <span>
                {((isVip && isStore) || tags?.indexOf('DUREX') >= 0 || tags?.indexOf('SANOFI') >= 0) && <SellerVip />}
                <LinkComp href={`/product/${slug || ''}/loading`} prefetch={false} style={{ padding: '0', display: 'inline' }}>
                  <Tooltip title={isDeal && deal ? deal?.name : name}>
                    <span
                      className={clsx(styles.product_name, {
                        [styles.product_name_mv2]: isMobileV2,
                      })}
                    >
                      {isTagGiftByPromotion()} {isDeal && deal ? deal?.name : name}
                    </span>
                  </Tooltip>
                </LinkComp>
              </span>
            </Typography>
          )}
        </div>
      </div>
      {!needHidePrice && (
        <div className={clsx(!isMobileV2 ? styles.wishlist_price : styles.mobile_wishlist_price)}>
          <GetPrice />
        </div>
      )}
      {expiredDate ? (
        <Tooltip title={expiredDate}>
          <Typography variant="body2" component="p" className={styles.product_expiredDate}>
            {translateCommon('expire')}: {formatDate3(expiredDate, true)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="body2" component="p" className={styles.product_expiredDate} />
      )}
      {volume ? (
        <Tooltip title={volume}>
          <Typography className={styles.product_volume} variant="body2" component="p">
            {volume}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className={styles.product_volume} variant="body2" component="p" />
      )}
      {/* {!isSellerList && (
        <div className={styles.seller_wrapper}>
          <SellerInfo seller={seller} tags={tags} isProductCard />
        </div>
      )} */}
    </CardContent>
  );
};
export default ProductCardContent;
