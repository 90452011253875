/* eslint-disable react/jsx-wrap-multilines */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Typography } from '@material-ui/core';
import ErrorIconOutlined from '@material-ui/icons/ErrorOutlined';
import { CustomerClient, isValidWithoutData } from 'clients';
import clsx from 'clsx';
import { Button, InputV2 } from 'components/atoms';
import { useTranslation } from 'next-i18next';
import { Controller, useForm } from 'react-hook-form';
import { gtag } from 'utils';
import NotifyUtils from 'utils/NotifyUtils';
import * as yup from 'yup';
import styles from './styles.module.css';

export default function NewRegisterOrderInvoiceForm({
  invoice,
  orderId,
  className,
  onClose,
  getValueInvoice,
  invoiceRes,
  invoiceInfoChooserItem,
  customerEmail,
  check,
}) {
  const { t: translateDetailOrder } = useTranslation('detailOrders');
  const schema = yup.object().shape({
    companyName: yup.string().required(translateDetailOrder('new_reg_order_invoice_form.validate_name')),
    taxCode: yup
      .string()
      // .matches(/^[0-9]+$/, 'Bạn chưa nhập mã số thuế')
      .min(10, translateDetailOrder('new_reg_order_invoice_form.validate_tax_number.min'))
      .max(14, translateDetailOrder('new_reg_order_invoice_form.validate_tax_number.max'))
      .test('check number', translateDetailOrder('new_reg_order_invoice_form.validate_tax_number.check_num'), (value) =>
        value.match(/^[0-9][0-9 -]*$/),
      )
      .test(
        'check-min-max',
        translateDetailOrder('new_reg_order_invoice_form.validate_tax_number.check_min_max'),
        (value) => !(value.length !== 10 && value.length !== 14),
      )
      .test('check -', translateDetailOrder('new_reg_order_invoice_form.validate_tax_number.check-'), (value) =>
        value.length === 14 ? value.includes('-') : true,
      ),
    companyAddress: yup.string().required(translateDetailOrder('new_reg_order_invoice_form.validate_address')),
  });
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  const handleSendRequest = async (value) => {
    const data = {
      ...value,
      orderId,
      email: invoice?.email || customerEmail || '',
      invoiceRequest: true,
    };

    try {
      const requestInvoiceResult = await CustomerClient.updateInvoiceInfoOrder(data);
      if (!isValidWithoutData(requestInvoiceResult)) {
        let message = '';
        const { errorCode } = requestInvoiceResult;
        switch (errorCode) {
          case 'ERR_UPDATE_MANUALLY_EXPORTED_INVOICE':
            message = translateDetailOrder('error_message.ERR_UPDATE_MANUALLY_EXPORTED_INVOICE');
            break;
          case 'ERR_UPDATE_MANUALLY_IMPORTED_INVOICE':
            message = translateDetailOrder('error_message.ERR_UPDATE_MANUALLY_IMPORTED_INVOICE');
            break;
          case 'ERR_UPDATE_INVOICE_MULTIPLE_TIMES':
            message = translateDetailOrder('error_message.ERR_UPDATE_INVOICE_MULTIPLE_TIMES');
            break;
          case 'ERR_INVALID_CHANGE_REQUEST_TIME':
            message = translateDetailOrder('error_message.ERR_INVALID_CHANGE_REQUEST_TIME');
            break;
          case 'ERR_INVALID_CHANGE_INVOICE_INFO_TIME':
            message = translateDetailOrder('error_message.ERR_INVALID_CHANGE_INVOICE_INFO_TIME');
            break;
          default:
            message = requestInvoiceResult.message || translateDetailOrder('error_message.DEFAULT');
        }

        throw new Error(message);
      }

      NotifyUtils.success(translateDetailOrder('success_message_send_invoice_update'));
      // clear
      onClose();
      getValueInvoice(data);
      gtag.requestInvoice();
    } catch (error) {
      NotifyUtils.error(error.message);
    }
  };
  return (
    <div className={clsx(className, styles.form_sign_up, styles.slideLeft)}>
      <form className={className} onSubmit={handleSubmit((data) => handleSendRequest(data))}>
        <Controller
          name="companyName"
          defaultValue={check ? invoiceInfoChooserItem?.companyName : invoiceRes?.companyName || ''}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputV2
                label={
                  <Box display="flex">
                    <Typography>{translateDetailOrder('new_reg_order_invoice_form.name')}</Typography>
                    <span className={styles.textRequired}>*</span>
                  </Box>
                }
                id="companyName"
                error={!!errors.companyName}
                className={styles.input}
                {...field}
              />

              {errors.companyName?.message && (
                <div className={styles.textError}>
                  <ErrorIconOutlined className={styles.iconError} /> {errors.companyName?.message}
                </div>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="taxCode"
          defaultValue={check ? invoiceInfoChooserItem?.taxCode : invoiceRes?.taxCode || ''}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputV2
                label={
                  <Box display="flex">
                    <Typography>{translateDetailOrder('new_reg_order_invoice_form.tax_number')}</Typography>
                    <span className={styles.textRequired}>*</span>
                  </Box>
                }
                error={!!errors.taxCode}
                className={styles.input}
                {...field}
              />

              {errors.taxCode?.message && (
                <div className={styles.textError}>
                  <ErrorIconOutlined className={styles.iconError} /> {errors.taxCode?.message}
                  {console.log('{errors.taxCode?.message}', errors.taxCode?.message)}
                </div>
              )}
            </FormControl>
          )}
        />
        {/* <Controller
          name="addressCompany"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputV2
                label="Địa chỉ công ty "
                required
                // placeholder="Nhập địa chỉ công ty (bao gồm Phường/Xã, Quận/Huyện, Tỉnh/Thành phố nếu có)"
                error={!!errors.addressCompany}
                // value={legalRepresentative}
                // onChange={(e) => handleSetValue('legalRepresentative', e.target.value)}
                className={styles.input}
                {...field}
                value={invoiceRes?.companyName}
              />

              {errors.addressCompany && (
                <div className={styles.textError} data-test="signup-err-username">
                  <ErrorIconOutlined className={styles.iconError} /> {errors.addressCompany?.message}
                </div>
              )}
            </FormControl>
          )}
        /> */}
        <Controller
          name="companyAddress"
          defaultValue={check ? invoiceInfoChooserItem?.companyAddress : invoiceRes?.companyAddress || ''}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputV2
                label={
                  <Box display="flex">
                    <Typography>{translateDetailOrder('new_reg_order_invoice_form.address')}</Typography>
                    <span className={styles.textRequired}>*</span>
                  </Box>
                }
                error={!!errors.companyAddress}
                className={styles.input}
                {...field}
              />

              {errors.companyAddress && (
                <div className={styles.textError} data-test="signup-err-username">
                  <ErrorIconOutlined className={styles.iconError} /> {errors.companyAddress.message}
                </div>
              )}
            </FormControl>
          )}
        />
        <Box textAlign="center" marginTop="30px">
          <Button className={styles.sendData} type="submit">
            {translateDetailOrder('new_reg_order_invoice_form.send')}
          </Button>
        </Box>
      </form>
    </div>
  );
}
